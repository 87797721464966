import Cookie from "../utils/manageCookie";
import { J_W_USER, J_TOKEN } from "../utils/Var";

export default function weChatAuth() {
    return new Promise((resolve, reject) => {
        const originQuery = location.search.slice(1).split("&");
        const query = {};
        originQuery.forEach(item => {
            const arr = item.split("=");
            query[arr[0]] = arr[1];
        });
        let w_user = query.w_user || Cookie.get(J_W_USER);
        
        if (w_user) {
            w_user = window.decodeURIComponent(w_user);
            Cookie.set(J_W_USER, w_user, '1d');
            axios.get("/wechat_login", {
                params: { w_user }
            }).then(res => {
                if (res.code == 1) {
                    localStorage.setItem(J_TOKEN, res.data.token);
                    localStorage.setItem("is_agent", res.data.is_agent);
                    localStorage.setItem("userid", res.data.userid);
                    localStorage.setItem("channel_id", res.data.channel_id);
                }
                resolve();
            }).catch(() => {
                resolve();
            });
        } else {
            const origin = process.env.VUE_APP_SHARE_LINK || "http://test.tuzuu.com";
            const url = window.btoa(location.href);
            location.href = origin + "/api/v1/wechat_oauth?r_url=" + url;
        }
    })
}