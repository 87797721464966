<template>
    <div class="bind-tel">
        <div class="desc">
            <div>为了更快捷的为您预约及保障检查内容安全</div>
            <div>请绑定手机号</div>
        </div>
        <van-form @submit="onSubmit" autocomplete="off">
            <van-field
                v-model.trim="tel"
                type="tel"
                name="tel"
                placeholder="请输入手机号"
             />
            <van-field
                v-model.trim="code"
                type="tel"
                name="code"
                maxlength="5"
                placeholder="请填写验证码"
            >
                <template #button>
                    <van-count-down
                        v-show="fetching"
                        :time="time"
                        ref="countDown"
                        :auto-start="false"
                        format="ss"
                        @finish="finish"
                        v-slot="timeData"
                    >
                        {{ timeData.seconds }}秒后重发
                    </van-count-down>

                    <van-button
                        v-show="!fetching"
                        size="small"
                        type="info"
                        @click="fetchCode"
                        native-type="button"
                    >
                        获取验证码
                    </van-button>
                </template>
            </van-field>
            <div style="margin-top: 40px;">
                <van-button round block type="info" native-type="submit">
                    确认绑定
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import Cookie from "@/utils/manageCookie";
import { J_W_USER, J_TOKEN } from "@/utils/Var";
import weChatAuth from "@/wechat/weChatAuth";

export default {
    name: "BindTel",
    data: () => ({
        code: "",
        tel: "",
        time: 60000,
        fetching: false,
        fetched: false,
    }),
    methods:{
        async fetchCode() {
            if (this.validate()) {
                sessionStorage.setItem("user_tel", this.tel);
                const res = await axios.get("/sendcode", {
                    params: {
                        tel: this.tel,
                    }
                });
                if (res.code == 1) {
                    localStorage.setItem("countDownStart", Date.now());
                    this.$toast("验证码已发送");
                    this.fetching = true;
                    this.start();
                } else {
                    this.$toast(res.message);      
                }
                console.log(res);
            }
        },
        async onSubmit() {
            if (this.validate()) {
                if (!this.code) {
                    this.$toast("请填写验证码");
                    return;
                }
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0,
                    message: '登录中...',
                });
                const res = await axios.get("/tel_login", {
                    params: {
                        tel: this.tel,
                        code: this.code,
                        w_user: Cookie.get(J_W_USER),
                    }
                })
                this.fetched = true;
                if (res.code == 1) {
                    localStorage.setItem(J_TOKEN, res.data.token);
                    toast.type = "success";
                    toast.message = "登录成功";
                    setTimeout(() => {
                        toast.clear();
                        const url = sessionStorage.getItem("callback_url");
                        sessionStorage.removeItem("count_down");
                        if (url) {
                            this.$router.push({path: url})    
                        } else {
                            this.$router.push("/home")
                        }
                    }, 2000);
                } else {
                    toast.clear();
                    this.$toast(res.message);
                }
            }
        },
        start() {
            this.$refs.countDown.start();
        },
        reset() {
            this.$refs.countDown.reset();
            this.time = 60000;
        },
        finish() {
            this.fetching = false;
            this.reset();
        },
        validate() {
            if (!this.tel) {
                this.$toast("请输入手机号");
                return;
            }
            if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.tel)) {
                this.$toast("请输入正确的手机号");
                return;
            }
            return true;
        }
    },
    mounted(){
        const tel = sessionStorage.getItem("user_tel");
        if (tel) {
            this.tel = tel;
        }

        const countDown = localStorage.getItem("countDownStart");
        if (countDown) {
            if ((Date.now() - countDown) < 60000) {
                this.time = 60000 - (Date.now() - countDown);
                this.fetching = true;
                Promise.resolve().then(() => {
                    this.start();
                });
            }
        }
    },
    beforeRouteEnter(to, from, next){
        const w_user = Cookie.get(J_W_USER);
        if (!w_user) {
            localStorage.removeItem(J_TOKEN);
            weChatAuth().then(() => {
                next();
            })
        } else {
            next();
        }
    }

}
</script>

<style lang="less" scoped>
.bind-tel {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 25%;

    .van-form {
        width: 80%;
        
        .van-cell {
            padding: 8px;

            &::after {
                left: 0;
                right: 0;
                border-color: #d4d4d4;
            }
        }
        .van-count-down {
            color: #ccc;
        }
    }
    .desc {
        font-size: 14px;
        line-height: 2;
        text-align: center;
        margin-bottom: 50px;

        div:last-of-type {
            font-weight: bold;
            font-size: 16px;
        }
    }

}
</style>